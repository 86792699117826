<template>
  <div>
      <footer>
          2022 &copy; Aarón Blanco Esteban
      </footer>
  </div>
</template>

<script>

export default {
  name: 'FooterComp'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    footer {
        width: 90%;
        margin: 60px auto 0;
        padding: 20px 0;
        border-top: 1px solid #333;
        color: #666;
    }
</style>
